import React from "react"
import Layout from "../../components/Layout"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { ConcreteTableEn } from "../../components/ConcreteTableEn"
import { ConcreteTable } from "../../components/ConcreteTable"
import SEO from "../../components/SEO"
import { useLanguage } from "../../contexts/LanguageContext"
const Concrete = ({
  data: {
    file: {
      childImageSharp: { fluid },
    },
  },
}) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook
  return (
    <Layout>
      <SEO title="Beton" />
      <div className="img-wrapper img-wrapper-about">
        <Image Tag="div" fluid={fluid} className=" bcg bcg-about  " />
        {selectedLanguage === "EN" ? (
          <h2 className="about-header visibility-filter contact-header">
            Concrete offer
          </h2>
        ) : (
          <h2 className="about-header visibility-filter contact-header">
            Ponuda Betona
          </h2>
        )}

        <h2 className="about-header mobile-visible">Beton</h2>
      </div>
      <div className="about-content">
        {selectedLanguage === "EN" ? (
          <div className="concrete-wrapper">
            <ConcreteTableEn />
          </div>
        ) : (
          <div className="concrete-wrapper">
            <ConcreteTable />
          </div>
        )}

        {selectedLanguage === "EN" ? (
          <h4 className="about-paragraph concrete-paragraph">
            <span className="concrete-span">*Note</span>: Additive Melfriz is
            added in winter on concrete batching plants of Osijek-Koteks. It is
            not included in price calculation and should be calculated
            additionally. All concrete mixes that we offer for sale are
            certified and controlled by authorized institutions and have all
            valid certificates of production and quality. For all inquiries
            regarding prices and delivery time, please contact our Purchasing
            and Marketing Department at: 091/284-1033 Nikolina Džanko
            (nikolina.dzanko@osijek-koteks.hr), 091/283-1626 Ana Vinković
            (ana.vinkovic@osijek-koteks.hr), 091/283-1139 Renata Tetkić
            (renata.tetkic@osijek-koteks.hr) or 091/283-1453 Ana Eržić
            (ana.erzic@osijek-koteks.hr) .
          </h4>
        ) : (
          <h4 className="about-paragraph concrete-paragraph">
            <span className="concrete-span">*Napomena</span>: Na betonarama
            Osijek-Koteksa za zimske uvjete dodaje se aditiv melfriz koji nije u
            kalkulaciji cijena i treba se naknadno zaračunati. Sve betonske
            mješavine koje nudimo na prodaju certificirane su i kontrolirane od
            strane ovlaštenih institucija i posjeduju sve valjane certifikate
            proizvodnje i kvalitete. Za sve upite vezane za cijenu i rokove
            isporuka molimo obratiti se odjelu centralne nabave i upravljanja
            nabavnim lancem na: 091/284-1033 Nikolina Džanko
            (nikolina.dzanko@osijek-koteks.hr), 091/283-1139 Renata Tetkić
            (renata.tetkic@osijek-koteks.hr), 091/283-1453 Ana Eržić
            (ana.erzic@osijek-koteks.hr) ili 091/283-1626 Ana Vinković
            (ana.vinkovic@osijek-koteks.hr) .
          </h4>
        )}

        {selectedLanguage === "EN" ? (
          <h4 className="about-paragraph concrete-text">
            You can see the general conditions of sale of concrete by clicking
            on{" "}
            <a
              className="asphalt-text"
              href="https://drive.google.com/uc?export=download&id=1XFBsEjlkz9YaIANfG1yRvyI540sW-iFV"
            >
              link.
            </a>
          </h4>
        ) : (
          <h4 className="about-paragraph concrete-text">
            Opće uvjete prodaje betona možete dobiti klikom na{" "}
            <a
              className="asphalt-text"
              href="https://drive.google.com/uc?export=download&id=1xR1M6gqz_OO3kRVTlOZkkhLeFd-Itf8s"
            >
              link.
            </a>
          </h4>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "pexels-scott-webb-2117937.jpg" }) {
      childImageSharp {
        fluid(
          sizes: "(max-width: 300px) calc(100vw - 25px), (max-width: 600px) calc(100vw - 15px), 1300px"
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default Concrete
